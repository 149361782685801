<template>
  <div class="box">
    <div v-if="pageName == 'personalCenter' && personalCenterList.length > 0">
      <div class="tag x-f">{{ tagList[4] }}</div>
      <div class="marketing x-w">
        <div v-for="(item, index) in personalCenterList" :key="index">
          <div
            class="y-c icon"
            :class="{ cursorBoder: item.cursor }"
            v-if="item.place.indexOf(pageName) != -1"
            @click="templateClick(5, index, item)"
            @mouseenter="basicsEnter(index, 'personalCenterList')"
            @mouseleave="basicsleave(index, 'personalCenterList')"
          >
            <iconpark-icon :name="item.icon" size="30" color="#333333"></iconpark-icon>
            <span class="marT10">{{ item.text }}</span>
            <span v-if="item.cursor">{{ item.num }}/{{ item.maximum }}</span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="pageName == 'categoryPage' && categoryPageList.length > 0">
      <div class="tag x-f">{{ tagList[1] }}</div>
      <div class="marketing x-w">
        <div v-for="(item, index) in categoryPageList" :key="index">
          <div
            class="y-c icon"
            :class="{ cursorBoder: item.cursor }"
            v-if="item.place.indexOf(pageName) != -1"
            @click="templateClick(4, index, item)"
            @mouseenter="basicsEnter(index, 'categoryPageList')"
            @mouseleave="basicsleave(index, 'categoryPageList')"
          >
            <iconpark-icon :name="item.icon" size="30" color="#333333"></iconpark-icon>
            <span class="marT10">{{ item.text }}</span>
            <span v-if="item.cursor">{{ item.num }}/{{ item.maximum }}</span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="pageName == 'goodsDetail' && goodsDetailList.length > 0">
      <div class="tag x-f">{{ tagList[3] }}</div>
      <div class="marketing x-w">
        <div v-for="(item, index) in goodsDetailList" :key="index">
          <div
            class="y-c icon"
            :class="{ cursorBoder: item.cursor }"
            @click="templateClick(3, index, item)"
            @mouseenter="basicsEnter(index, 'goodsDetailList')"
            @mouseleave="basicsleave(index, 'goodsDetailList')"
          >
            <iconpark-icon :name="item.icon" size="30" color="#333333"></iconpark-icon>
            <span class="marT10">{{ item.text }}</span>
            <span v-if="item.cursor">{{ item.num }}/{{ item.maximum }}</span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="basicsIconList.length > 0">
      <div class="tag x-f">{{ tagList[0] }}</div>
      <div class="basics x-w">
        <div v-for="(item, index) in basicsIconList" :key="index">
          <el-tooltip
            :disabled="!item.developing"
            effect="dark"
            content="还在开发中"
            placement="top"
          >
            <div
              class="y-c icon"
              :class="{ cursorBoder: item.cursor && !item.developing }"
              @click="templateClick(item.developing ? -99 : 1, index, item)"
              @mouseenter="basicsEnter(index, 'basicsIconList')"
              @mouseleave="basicsleave(index, 'basicsIconList')"
              v-if="item.place.indexOf(pageName) != -1"
            >
              <iconpark-icon :name="item.icon" size="30" color="#333333" />
              <span class="marT10">{{ item.text }}</span>
              <span v-if="item.cursor">{{ item.num }}/{{ item.maximum }}</span>
            </div>
          </el-tooltip>
        </div>
      </div>
    </div>
    <div v-if="pageName != 'navigation'&&marketingIconList.length > 0">
      <div class="tag x-f">{{ tagList[2] }}</div>
      <div class="marketing x-w">
        <div v-for="(item, index) in marketingIconList" :key="index">
          <el-tooltip
            :disabled="!item.developing"
            effect="dark"
            content="还在开发中"
            placement="top"
          >
            <div
              class="y-c icon"
              :class="{ cursorBoder: item.cursor && !item.developing }"
              v-if="item.place.indexOf(pageName) != -1"
              @click="templateClick(item.developing ? -99 : 2, index, item)"
              @mouseenter="basicsEnter(index, 'marketingIconList')"
              @mouseleave="basicsleave(index, 'marketingIconList')"
            >
              <iconpark-icon :name="item.icon" size="30" color="#333333"></iconpark-icon>
              <span class="marT10">{{ item.text }}</span>
              <span v-if="item.cursor && !item.developing"
                >{{ item.num }}/{{ item.maximum }}</span
              >
            </div>
          </el-tooltip>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { deepCopy, isArray } from "@/utils";
export default {
  name: "left-template",
  data() {
    return {
      tagList: ["基础组件", "分类页组件", "营销组件", "商品详情组件", "个人中心组件"],
      tagIndex: 0,
      labelList: [],
    };
  },
  computed: {
    ...mapState({
      pageName: (state) => state.template.pageName,
      // 基础数据
      basicsIcon: (state) => state.template.basicsIcon,
      marketingIcon: (state) => state.template.marketingIcon,
      goodsDetail: (state) => state.template.goodsDetail,
      categoryPage: (state) => state.template.categoryPage,
      personalCenter: (state) => state.template.personalCenter,
      // 使用数据
      basicsIconList: (state) => state.template.basicsIconList,
      marketingIconList: (state) => state.template.marketingIconList,
      goodsDetailList: (state) => state.template.goodsDetailList,
      categoryPageList: (state) => state.template.categoryPageList,
      personalCenterList: (state) => state.template.personalCenterList,
    }),
  },
  created() {
    this.$store.state.template.basicsIconList = JSON.parse(
      JSON.stringify(this.basicsIcon)
    );
    this.$store.state.template.marketingIconList = JSON.parse(
      JSON.stringify(this.marketingIcon)
    );
    this.$store.state.template.goodsDetailList = JSON.parse(
      JSON.stringify(this.goodsDetail)
    );
    this.$store.state.template.categoryPageList = JSON.parse(
      JSON.stringify(this.categoryPage)
    );
    this.$store.state.template.personalCenterList = JSON.parse(
      JSON.stringify(this.personalCenter)
    );
  },
  methods: {
    templateClick(type, index, item) {
      console.log(type, index, item, "click");
      let obj;
      switch (type) {
        case 1:
          // 基础组件
          obj = this.addBasics(this.basicsIconList[index].name, item);
          break;
        case 2:
          // 营销组件
          obj = this.addBasics(this.marketingIconList[index].name, item);
          break;
        case 3:
          // 商品详情组件
          obj = this.addBasics(this.goodsDetailList[index].name, item);
          break;
        case 4:
          // 分类页组件
          obj = this.addBasics(this.categoryPageList[index].name, item);
          break;
        case 5:
          // 个人中心组件
          obj = this.addBasics(this.personalCenterList[index].name, item);
          break;
        default:
          break;
      }
      if (obj == undefined || obj == "undefined") return;
      if (this.$store.state.template.selectIndex >= 0) {
        this.$store.state.template.tempData.splice(
          this.$store.state.template.selectIndex + 1,
          0,
          obj
        );
      } else {
        this.$store.state.template.tempData.push(obj);
      }
      if (!this.$store.state.template.rightData) {
        this.$store.state.template.rightData = obj;
      }

      // this.$emit('addTemplate', obj)
      this.$store.state.template.tempData.sort((a, b) => {
        if ((a.name === "dibudaohang" && b.name !== "dibudaohang")||(a.name === "navigation" && b.name !== "navigation")) {
          return 1;
        } else if ((a.name !== "dibudaohang" && b.name === "dibudaohang")||(a.name !== "navigation" && b.name === "navigation")) {
          return -1;
        } else {
          return 0;
        }
      });
    },
    // 添加基础组件
    addBasics(name, item) {
      console.log("----name", name, item);
      // let num = this.maintainNum(this.basicsIconList, name)
      if (name == "tanchuang" && item.num == 1) return;
      const lists = [
        "basicsIconList",
        "marketingIconList",
        "goodsDetailList",
        "categoryPageList",
        "personalCenterList",
      ];
      for (const listName of lists) {
        if (this.maintainNum(listName, name)) {
          return;
        }
      }
      let obj = {};
      if (name == "mofang") {
        obj = {
          name: name,
          imgList: [],
          style: {
            type: 1, //
            sxmargin: 5,
            marT: 5, //上边距
            marB: 5, //下边距
            marL: 10, //左边距
            marR: 10, //右边距
            marN: 10, //内边距
            borRadius: 10, //圆角
          },
        };
      } else if (name == "rubikcube") {
        obj = {
          name: name,
          imgList: [
            {
              url: "",
              link: "",
              name: "",
            },
            {
              url: "",
              link: "",
              name: "",
            },
            {
              url: "",
              link: "",
              name: "",
            },
            {
              url: "",
              link: "",
              name: "",
            },
          ],
          style: {
            type: "1", //
            sxmargin: 0,
            marO: 0,
            marT: 0, //上边距
            marB: 0, //下边距
            marL: 0, //左边距
            marR: 0, //右边距
            marN: 0, //内边距
            borRadius: 0, //圆角
          },
        };
      } else if (name == "switching") {
        //底部导航栏
        obj = {
          name: name,
          isLayout: 0, //样式
          logType: 0,
          bgImg: "",
          loImg: "",
          style: {
            navShape: "ordinary", //导航样式
            shopNameColor: "#333333", //门店名称
            textColor: "#333333", //门店地址
            angleMarkColor: "#FF3C29", //门店距离
            leftBtnColor: "#212121", //分割线
            rightBtnColor: "#FF3C29", //右按钮样式
            btnBgColor: "#FF3C29", //按钮背景
            bgColor: "#FFFFFF", //背景
            iconColor: "#fff",
            iconText: "#fff",
            iconBor: "#333333",
          }, //内容样式
          seeMoreIcon: "",
          marT: 0, //上边距
          marB: 0, //下边距
          marL: 0, //左边距
          marR: 0, //右边距
          borRadius: 10, //圆角
        };
      } else if (name == "wenben") {
        obj = {
          name: name,
          title: "", // 主标题
          subhead: "", // 副标题
          link: { name: "", url: "" }, // 跳转链接
          style: {
            location: "y-start", // 位置，居中/左对齐
            titleFontSize: "16", // 主标题字体大小
            subheadFontSize: "14", // 副标题字体大小
            titleWeight: "", // 标题加粗
            subheadWeight: "", // 副标题加粗
            titleColor: "#323233", // 主标题颜色
            subheadColor: "#969799", // 副标题颜色
            bgColor: "#FFFFFF", // 背景颜色
            borderB: "false", // 底部分割线
          },
        };
      } else if (name == "fengefuzhu") {
        obj = {
          name: name,
          style: {
            borderHeight: 10, // 高度
            borderLR: false, // 左右留白
            auxiliaryType: "wuxian", // 分割类型
            lineStyle: "solid", // 线条样式
            color: "#d0d0d5", // 分割线颜色
            backGauge: 0, // 左右边距
          },
        };
      } else if (name == "gonggao") {
        obj = {
          name: name,
          title: "请输入公告内容", // 公告内容
          style: {
            bgColor: "#fdf7e8", // 背景颜色
            textColor: "#646566", // 文字颜色
          },
        };
      } else if (name == "sousuo") {
        obj = {
          name: name,
          style: {
            isShowScan: false, // 是否显示扫一下
            frameworkStyle: 0, // 框体样式
            textPoint: "x-f", // 文本位置
            frameworkHeigth: 40, // 框体高度
            bgColor: "#F1F1F1", // 背景颜色
            framworkColor: "#FFFFFF", // 框体颜色
            textColor: "#C1C2C4", // 字体颜色
            style: "guding",
            isFixed: false, // 是否固定显示
          },
          marT: 0, //上边距
          marB: 0, //下边距
          marL: 0, //左边距
          marR: 0, //右边距
          borRadius: 10, //圆角
        };
      } else if (name == "imageNav") {
        obj = {
          name: name,
          style: {
            bgColor: "#ffffff", // 背景颜色
            textColor: "#000000", // 文字颜色
          },
          styleImage: "fixed", // 图片样式
          textFormworkNav: "imageFormwork", //选择模板单选
          immageNavList: [
            {
              title: "导航一",
              link: "",
              imgUrl:
                "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-06-15/0c878c59403b4a7fb461b4cb073047f4.png",
            },
            {
              title: "导航二",
              link: "",
              imgUrl:
                "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-06-15/0c878c59403b4a7fb461b4cb073047f4.png",
            },
            {
              title: "导航三",
              link: "",
              imgUrl:
                "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-06-15/0c878c59403b4a7fb461b4cb073047f4.png",
            },
            {
              title: "导航四",
              link: "",
              imgUrl:
                "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-06-15/0c878c59403b4a7fb461b4cb073047f4.png",
            },
          ], //图文导航数组
        };
      } else if (name == "tanchuang") {
        obj = {
          name: name,
          style: {
            bgColor: "#ffffff", // 背景颜色
            textColor: "#000000", // 文字颜色
          },
          styleImage: "fixed", // 图片样式
          textFormworkNav: "imageFormwork", //选择模板单选
          immageNavList: [
            {
              title: "导航一",
              link: "",
              imgUrl:
                "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-06-15/0c878c59403b4a7fb461b4cb073047f4.png",
            },
          ],
          tableList: ["会员中心", "分类页", "商品详情", "商城首页"],
          checkedList: ["会员中心", "分类页", "商品详情", "商城首页"],
        };
      } else if (name == "goods") {
        //商品
        obj = {
          name: name,
          style: {
            bgColor: "#ffffff", // 背景颜色
            textColor: "#000000", // 文字颜色
          },
          //当前列表样式下标
          curListStyleIndex: 1,
          //当前商品样式下标
          curGoodsStyleIndex: 0,
          //选择购物车开关
          selectShoppingCart: false,
          //当前购物车样式下标
          curShoppingCardIndex: 0,
          //购物车按钮文字
          btnText: "去开团",
          //商品图片宽高
          photoWidthHeight: {
            height: "",
            width: "100%",
          },
          //商品倒角
          goodsBorderAngle: {
            borTL: 0,
            borTR: 0,
            borBR: 0,
            borBL: 0,
          },
          //商品图片宽高(一大两小(混合列表使用))
          photoWidthHeightTwo: {
            height: "",
            width: "100%",
          },
          // 临时商品数据
          temporaryGoodList: [
            {
              goodsName: "商品名称",
              sellingPoints: "商品描述",
              activityPrice: 99,
              liningPrice: 999,
              goodsUrl:
                "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-06-15/0c878c59403b4a7fb461b4cb073047f4.png",
            },
            {
              goodsName: "商品名称",
              sellingPoints: "商品描述",
              activityPrice: 99,
              liningPrice: 999,
              goodsUrl:
                "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-06-15/0c878c59403b4a7fb461b4cb073047f4.png",
            },
            {
              goodsName: "商品名称",
              sellingPoints: "商品描述",
              activityPrice: 99,
              liningPrice: 999,
              goodsUrl:
                "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-06-15/0c878c59403b4a7fb461b4cb073047f4.png",
            },
          ],
          goodList: [],
          singleGood: {
            goodsName: "商品名称",
            sellingPoints: "商品描述",
            activityPrice: 99,
            liningPrice: 999,
            goodsUrl:
              "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-06-15/0c878c59403b4a7fb461b4cb073047f4.png",
          },
          //更多设置
          moreSet: {
            //是否显示商品名称
            isShowName: true,
            //是否显示商品描述
            isShowDescribe: true,
            //是否显示商品价格
            isShowSalePrice: true,
            //是否显示商品原价
            isShowOriginalPrice: true,
            //售罄商品置底显示
            isSellOutBottom: true,
            // 商品倒角
            goodsBorderAngle: "rightAngle",
            //商品比例
            goodsRatio: "1:1",
            //是否图片填充
            isPicFill: true,
            //文本样式(常规/加粗)
            textStyle: "常规",
            //文本对齐(左对齐,居中对齐)
            textAlignment: "left",
            //页面边距
            pageBackGauge: 10,
            //商品边距
            goodsBackGauge: 5,
            //商品角标(不显示0/营销角标1/属性角标2)
            goodsCornerMark: 0,
            //选择角标开关
            selectCornerMark: false,
            //当前角标样式下标
            curCornerMarkIndex: 0,
            //自定义角标url
            cornerMarkUrl: undefined,
          },
        };
      } else if (name == "guanggao") {
        obj = {
          name: name,
          style: {
            type: "singleton", // singleton: 一行一个 ， swiper：轮播 ， roll： 滚动
            imgList: [
              {
                image:"https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-06-15/0c878c59403b4a7fb461b4cb073047f4.png", // 图片链接
                bgImage:"https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-06-15/0c878c59403b4a7fb461b4cb073047f4.png", // 图片链接
                hint: "", // 图片提示信息
                path: "", // 跳转链接
              },
            ],
            height: "",
            imgStyle: "original", // 图片样式 常规：original  ，投影：projection
            cornerStyle: "square", // 边角样式 方角： square   圆角： round
            pageBackGauge: 12, // 页面边距
            imgbackGuge: 0, // 图片边距
            imgNum: 1, // 图片数量
            interval: 3000,
          },
        };
      } else if (name == "kefu") {
        obj = {
          name: name,
          style: {
            icon: "font-size",
          },
        };
      // this.addFixationTemp(name);
      } else if (name == "videoTmp") {
        obj = {
          name: name,
          style: {
            videoUrl: "", // 视频链接
            videoSource: "", // 视频来源
            height: 200, // 播放器高度
            coverImg: "", // 封面图片
            // autoplay: false, // 是否自动播放
            pagebackGauge: 0, // 页面边距
            progressBar: false, // 是否显示进度条
            voluntarilyPlay: true, // 是否自动播放
            chamfer: "rightAngle", // circle： 圆角，
          },
        };
      } else if (name == "zhangfen") {
        obj = {
          name: name,
          style: {
            type: "WeChat", // WeChat: 微信/群 ，publicNum: 公众号
            btnName: "立即添加", // 按钮名字
            title: "标题", // 标题
            describe: "描述", // 描述
            isDefault: 1, // 是否默认 1：默认  2：自定义
            imgUrl:
              "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-06-15/0c878c59403b4a7fb461b4cb073047f4.png", // 默认图片
            bgColor: "#FFFFFF", // 背景颜色
            applet: false, // 小程序
            appletGopublicNum: false, // 小程序跳转公众号文章关注
            h5: false, // H5
          },
        };
      } else if (name == "zichan") {
        obj = {
          name: name,
          style: {
            templateNo: 1, // 模板编号
            titleName: "我的", // 标题/昵称
            propertyMsg: "查看更多", // 资产信息
            avatar:
              "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-06-15/0c878c59403b4a7fb461b4cb073047f4.png", // 头像地址
            isAvatar: true, // 是否显示头像  true: 显示
            integralNum: 0, // 积分数
            couponNum: 0, // 优惠券数
            isVipQrCode: true, // 是否显示会员码
            description: 1, // 资产描述类型
            descriptionText: "积分", // 资产描述
            textColor: "#cccccc", // 文字颜色
            bgColor: "#ffffff", // 背景颜色
            cardBgColor: "#ffffff", // 卡片背景颜色
            bGopacity: -1, // 背景透明度
            bgImage: "", // 背景图片
            pagePadding: 0, // 页面边距
            chamfer: 0, // 倒角/圆角度数
            cardStyle: 1, // 卡片样式 1：无边白底 2：卡片投影 3：描边白底 4：无边透明底
            bgStyle: "color", // 背景样式 color: 颜色  image: 图片
            descriptionArr: [
              { label: "积分", value: 1, open: true, num: 0 },
              { label: "优惠券", value: 2, open: true, num: 0 },
              { label: "余额", value: 3, open: true, num: 0 },
            ], // 模板二时 选择显示的会员信息列表
          },
          marT: 0, //上边距
          marB: 0, //下边距
          marL: 0, //左边距
          marR: 0, //右边距
          borRadius: 10, //圆角
        };
      } else if (name == "coupon") {
        // 优惠券
        obj = {
          name: name,
          style: {
            colorId: 1, // 优惠券颜色ID
            couponColor: "#fe5f3a", // 优惠券颜色
            textColor: "#ffffff", // 文字顏色
            addManner: "1", // 添加优惠券方式  1：手动添加  2：自动获取
            couponNumMode: "1", // 自动获取-券活动计算方式
            couponNum: "", // 自动获取-优惠券数量
            conceal: false, // 是否隐藏不可分享的优惠券
            concealDisabled: false, // 隐藏已抢完及失效的券
            //当前列表样式下标
            curListStyleIndex: 0,
            //当前卡片样式下标
            curCardStyleIndex: 0,
          },
          temporaryCouponList: [
            {
              sillType:0,
              goodsRangeType:0,
              sillMoney:0,
              couponCaseType:0,
              parValue:1,
              couponCaseName: "满100减30", // 优惠券名称
              couponCaseId: undefined, // 优惠券ID
              parValue: "30", // 优惠券面值
              couponDesc: "无门槛，全部商品", // 优惠券说明
              isStop: "", // 是否启用
            },
            {
               sillType:0,
              goodsRangeType:0,
              sillMoney:0,
              couponCaseType:0,
              parValue:1,
              couponCaseName: "满100减30", // 优惠券名称
              couponCaseId: undefined, // 优惠券ID
              parValue: "30", // 优惠券面值
              couponDesc: "无门槛，全部商品", // 优惠券说明
              isStop: "", // 是否启用
            },
            {
               sillType:0,
              goodsRangeType:0,
              sillMoney:0,
              couponCaseType:0,
              parValue:1,
              couponCaseName: "满100减30", // 优惠券名称
              couponCaseId: undefined, // 优惠券ID
              parValue: "30", // 优惠券面值
              couponDesc: "无门槛，全部商品", // 优惠券说明
              isStop: "", // 是否启用
            },
          ],
          couponList: [],
        };
      } else if (name == "pintuan") {
        // 拼团
        obj = {
          name: name,
          style: {
            bgColor: "#ffffff", // 背景颜色
            textColor: "#000000", // 文字颜色
          },
          groupingType: "1", // 拼团类型
          groupingType2: 1, // 拼团类型：获取商品的方式
          //当前列表样式下标
          curListStyleIndex: 1,
          //当前商品样式下标
          curGoodsStyleIndex: 0,
          //选择购物车开关
          selectShoppingCart: false,
          //当前购物车样式下标
          curShoppingCardIndex: 0,
          //购物车按钮文字
          btnText: "马上抢",
          //商品倒角
          goodsBorderAngle: {
            borTL: 0,
            borTR: 0,
            borBR: 0,
            borBL: 0,
          },
          //商品图片宽高
          photoWidthHeight: {
            height: "",
            width: "100%",
          },
          //商品图片宽高(一大两小(混合列表使用))
          photoWidthHeightTwo: {
            height: "",
            width: "100%",
          },
              // 临时数据
          temporaryGoodList: [
            {
              goodsName: "商品名称",
              sellingPoints: "商品描述",
              activityPrice: 99,
              liningPrice: 999,
              goodsUrl: "",
            },
            {
              goodsName: "商品名称",
              sellingPoints: "商品描述",
              activityPrice: 99,
              liningPrice: 999,
              goodsUrl: "",
            },
            {
              goodsName: "商品名称",
              sellingPoints: "商品描述",
              activityPrice: 99,
              liningPrice: 999,
              goodsUrl: "",
            },
          ],
          goodList: [], // 商品列表
          singleGood: {
            goodsName: "商品名称",
            sellingPoints: "商品描述",
            activityPrice: 99,
            liningPrice: 999,
            goodsUrl:
              "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-06-15/0c878c59403b4a7fb461b4cb073047f4.png",
          },
          //更多设置
          moreSet: {
            //是否显示商品名称
            isShowName: true,
            //是否显示商品描述
            isShowDescribe: true,
            //是否显示拼团价
            isShowFightGroupPrice: true,
            //是否显示划线价
            isShowOriginalPrice: true,
            // 是否显示商品倒计时
            isShowCountDown: true,
            // 是否显示已团人数
            alreadyHaveNum: true,
            //售完商品置底显示
            isSellOutBottom: true,
            //商品倒角
            goodsBorderAngle: "rightAngle",
            //商品比例
            goodsRatio: "1:1",
            //是否图片填充
            isPicFill: true,
            //文本样式(常规/加粗)
            textStyle: "常规",
            //文本对齐(左对齐,居中对齐)
            textAlignment: "left",
            //页面边距
            pageBackGauge: 0,
            //商品边距
            goodsBackGauge: 10,
            //商品角标(不显示0/营销角标1/属性角标2)
            goodsCornerMark: 0,
            //选择角标开关
            selectCornerMark: false,
            //当前角标样式下标
            curCornerMarkIndex: 0,
            //自定义角标url
            cornerMarkUrl: undefined,
          },
        };
      } else if (name == "miaosha") {
        // 秒杀
        obj = {
          name: name,
          style: {
            bgColor: "#ffffff", // 背景颜色
            textColor: "#000000", // 文字颜色
          },
          //当前列表样式下标
          curListStyleIndex: 1,
          //当前商品样式下标
          curGoodsStyleIndex: 0,
          //选择购物车开关
          selectShoppingCart: false,
          //当前购物车样式下标
          curShoppingCardIndex: 0,
          //购物车按钮文字
          btnText: "马上抢",
          //商品倒角
          goodsBorderAngle: {
            borTL: 0,
            borTR: 0,
            borBR: 0,
            borBL: 0,
          },
          //商品图片宽高
          photoWidthHeight: {
            height: "",
            width: "100%",
          },
          //商品图片宽高(一大两小(混合列表使用))
          photoWidthHeightTwo: {
            height: "",
            width: "100%",
          },
          // 临时数据
          temporaryGoodList: [
            {
              goodsName: "商品名称",
              sellingPoints: "商品描述",
              activityPrice: 99,
              liningPrice: 999,
              goodsUrl: "",
            },
            {
              goodsName: "商品名称",
              sellingPoints: "商品描述",
              activityPrice: 99,
              liningPrice: 999,
              goodsUrl: "",
            },
            {
              goodsName: "商品名称",
              sellingPoints: "商品描述",
              activityPrice: 99,
              liningPrice: 999,
              goodsUrl: "",
            },
          ],
          goodList: [], // 商品列表
          singleGood: {
            goodsName: "商品名称",
            sellingPoints: "商品描述",
            activityPrice: 99,
            liningPrice: 999,
            goodsUrl: "",
          },
          //更多设置
          moreSet: {
            //是否显示商品名称
            isShowName: true,
            //是否显示商品描述
            isShowDescribe: true,
            //是否显示商品价
            isShowFightGroupPrice: true,
            //是否显示划线价
            isShowOriginalPrice: true,
            // 是否显示商品倒计时
            isShowCountDown: true,
            // // 是否显示已团人数
            // isShowAlreadyGroupSize: true,
            //售完商品置底显示
            isSellOutBottom: true,
            // 是否显示已售完商品
            activityFinish: true,
            //商品倒角
            goodsBorderAngle: "rightAngle",
            //商品比例
            goodsRatio: "1:1",
            //是否图片填充
            isPicFill: true,
            //文本样式(常规/加粗)
            textStyle: "常规",
            //文本对齐(左对齐,居中对齐)
            textAlignment: "left",
            //页面边距
            pageBackGauge: 0,
            //商品边距
            goodsBackGauge: 10,
            //商品角标(不显示0/营销角标1/属性角标2)
            goodsCornerMark: 0,
            //选择角标开关
            selectCornerMark: false,
            //当前角标样式下标
            curCornerMarkIndex: 0,
            //自定义角标url
            cornerMarkUrl: undefined,
          },
        };
      } else if (name == "zhekou") {
        // 限时折扣
        obj = {
          name: name,
          style: {
            bgColor: "#ffffff", // 背景颜色
            textColor: "#000000", // 文字颜色
          },
          //当前列表样式下标
          curListStyleIndex: 1,
          //当前商品样式下标
          curGoodsStyleIndex: 0,
          //选择购物车开关
          selectShoppingCart: false,
          //当前购物车样式下标
          curShoppingCardIndex: 0,
          //购物车按钮文字
          btnText: "马上抢",
          //商品图片宽高
          photoWidthHeight: {
            height: "",
            width: "100%",
          },
          //商品图片宽高(一大两小(混合列表使用))
          photoWidthHeightTwo: {
            height: "",
            width: "100%",
          },
          // 临时商品数据
          temporaryGoodList: [
            {
              goodsName: "商品名称",
              sellingPoints: "商品描述",
              activityPrice: 99,
              liningPrice: 999,
              goodsUrl:
                "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-06-15/0c878c59403b4a7fb461b4cb073047f4.png",
            },
            {
              goodsName: "商品名称",
              sellingPoints: "商品描述",
              activityPrice: 99,
              liningPrice: 999,
              goodsUrl:
                "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-06-15/0c878c59403b4a7fb461b4cb073047f4.png",
            },
            {
              goodsName: "商品名称",
              sellingPoints: "商品描述",
              activityPrice: 99,
              liningPrice: 999,
              goodsUrl:
                "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-06-15/0c878c59403b4a7fb461b4cb073047f4.png",
            },
          ],
          goodList: [], // 商品列表
          singleGood: {
            goodsName: "商品名称",
            sellingPoints: "商品描述",
            activityPrice: 99,
            liningPrice: 999,
            goodsUrl:
              "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-06-15/0c878c59403b4a7fb461b4cb073047f4.png",
          },
          //更多设置
          moreSet: {
            //是否显示商品名称
            isShowName: true,
            //是否显示商品描述
            isShowDescribe: true,
            //是否显示商品价
            isShowFightGroupPrice: true,
            //是否显示划线价
            isShowOriginalPrice: true,
            // 是否显示商品倒计时
            isShowCountDown: true,
            // 是否显示剩余件数
            isShowResidue: true,
            // 是否显示抢购进度条
            isShowSchedule: true,
            //售完商品置底显示
            isSellOutBottom: true,
            // 是否显示已售完商品
            activityFinish: true,
            //商品倒角
            goodsBorderAngle: "rightAngle",
            //商品比例
            goodsRatio: "1:1",
            //是否图片填充
            isPicFill: true,
            //文本样式(常规/加粗)
            textStyle: "常规",
            //文本对齐(左对齐,居中对齐)
            textAlignment: "left",
            //页面边距
            pageBackGauge: 10,
            //商品边距
            goodsBackGauge: 5,
            // //商品角标(不显示0/营销角标1/属性角标2)
            // goodsCornerMark: 0,
            // //选择角标开关
            // selectCornerMark: false,
            // //当前角标样式下标
            // curCornerMarkIndex: 0,
            // //自定义角标url
            // cornerMarkUrl: undefined
          },
        };
      } else if (name == "gexinghua") {
        //个性化推荐
        obj = {
          name: name,
          style: {
            bgColor: "#ffffff", // 背景颜色
            textColor: "#000000", // 文字颜色
          },
          singleGood: {},
          goodList: [
            {
              goodsName: "商品名称",
              sellingPoints: "商品描述",
              activityPrice: 99,
              liningPrice: 999,
              goodsUrl:
                "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-06-15/0c878c59403b4a7fb461b4cb073047f4.png",
            },
            {
              goodsName: "商品名称",
              sellingPoints: "商品描述",
              activityPrice: 99,
              liningPrice: 999,
              goodsUrl:
                "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-06-15/0c878c59403b4a7fb461b4cb073047f4.png",
            },
            {
              goodsName: "商品名称",
              sellingPoints: "商品描述",
              activityPrice: 99,
              liningPrice: 999,
              goodsUrl:
                "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-06-15/0c878c59403b4a7fb461b4cb073047f4.png",
            },
          ], // 商品列表
          //当前列表样式下标
          curListStyleIndex: 1,
          //当前商品样式下标
          curGoodsStyleIndex: 0,
          //选择购物车开关
          selectShoppingCart: false,
          //当前购物车样式下标
          curShoppingCardIndex: 0,
          //购物车按钮文字
          btnText: "去开团",
          //商品图片宽高
          photoWidthHeight: {
            height: "",
            width: "100%",
          },
          //商品图片宽高(一大两小(混合列表使用))
          photoWidthHeightTwo: {
            height: "",
            width: "100%",
          },
          //更多设置
          moreSet: {
            //是否显示商品名称
            isShowName: true,
            //是否显示商品描述
            isShowDescribe: true,
            //是否显示商品价格
            isShowSalePrice: true,
            //是否显示商品原价
            isShowOriginalPrice: true,
            //售罄商品置底显示
            isSellOutBottom: true,
            //商品倒角
            goodsBorderAngle: "rightAngle",
            //商品比例
            goodsRatio: "1:1",
            //是否图片填充
            isPicFill: true,
            //文本样式(常规/加粗)
            textStyle: "常规",
            //文本对齐(左对齐,居中对齐)
            textAlignment: "left",
            //页面边距
            pageBackGauge: 10,
            //商品边距
            goodsBackGauge: 5,
            //商品角标(不显示0/营销角标1/属性角标2)
            goodsCornerMark: 0,
            //选择角标开关
            selectCornerMark: false,
            //当前角标样式下标
            curCornerMarkIndex: 0,
            //自定义角标url
            cornerMarkUrl: undefined,
          },
        };
      } else if (name == "shangpintu") {
        //商品图
        obj = {
          name: name,
          shape: "yuanjiao",
          position: "center",
          bgColor: "#ffffff",
        };
      } else if (name == "shangpinxinxi") {
        //商品信息
        obj = {
          name: name,
          isUnit: true,
          isMarketing: true, //营销
          marketingStyle: {
            textColor: "#FF3C29",
            bgColor: "#fff3f5",
          }, //营销样式
          isService: true, //服务
          serviceStyle: {
            shape: "duihao",
            labelColor: "#969696", //标签
            textColor: "#969696", //文字
            bgColor: "#ffffff", //背景
          }, //服务样式
          contentStyle: {
            titleColor: "#212121", //标题
            subtitleColor: "#969696", //副标题
            priceColor: "#FF3C29", //价格
            salesVolumeColor: "#969696", //销量
            bgColor: "#FFFFFF", //背景
          }, //内容样式
          marT: 5, //上边距
          marB: 5, //下边距
          marL: 10, //左边距
          marR: 10, //右边距
          borRadius: 10, //圆角
        };
      } else if (name == "shangpinguige") {
        //商品规格
        obj = {
          name: name,
          contentStyle: {
            titleColor: "#969696", //标题
            textColor: "#212121", //文字
            bgColor: "#FFFFFF", //背景
          }, //内容样式
          marT: 5, //上边距
          marB: 5, //下边距
          marL: 10, //左边距
          marR: 10, //右边距
          borRadius: 10, //圆角
        };
      } else if (name == "shangpinpingjia") {
        //商品评价
        obj = {
          name: name,
          contentStyle: {
            isNumberEvaluations: true, //评价条数
            isReadMore: true, //查看更多
            isVipLevel: true, //会员等级
            isEvaluationLeve: true, //评价等级
            isGoodsSpece: true, //商品规格
            isTimeEvaluations: true, //评价时间
            isImgEvaluations: true, //评价图片
            titleColor: "#212121", //标题
            bgColor: "#FFFFFF", //背景
          }, //内容样式
          marT: 5, //上边距
          marB: 5, //下边距
          marL: 10, //左边距
          marR: 10, //右边距
          borRadius: 10, //圆角
        };
      } else if (name == "shangpinxiangqing") {
        //商品详情
        obj = {
          name: name,
          contentStyle: {
            isSpacing: true, //评价图片
            bgColor: "#FFFFFF", //背景
          }, //内容样式
          marT: 5, //上边距
          marB: 5, //下边距
          marL: 10, //左边距
          marR: 10, //右边距
          borRadius: 10, //圆角
        };
      } else if (name == "dibudaohang") {
        //底部导航栏
        obj = {
          name: name,
          contentStyle: {
            navShape: "ordinary", //导航样式
            iconColor: "#333333", //图标样式
            textColor: "#333333", //文字样式
            angleMarkColor: "#ff0000", //角标样式
            leftBtnColor: "#F39C30", //左按钮样式
            rightBtnColor: "#F3421A", //右按钮样式
            bgColor: "#ffffff", //背景
          }, //内容样式
          iconStyleList: [
            {
              name: "收藏",
              isEnable: true,
              link: { url: "", name: "" }, //跳转链接
              titleIconList: [
                { name: "收藏前图标", icon: "icon-wujiaoxing-xianxing" },
                { name: "收藏后图标", icon: "icon-wujiaoxing-mianxing" },
              ],
              textInputList: [
                { name: "收藏前文字", value: "收藏" },
                { name: "收藏后文字", value: "已收藏" },
              ],
            },
            {
              name: "店铺",
              isEnable: true,
              link: { url: "/pages/tabbar/home/index", name: "店铺" }, //跳转链接
              titleIconList: [{ name: "图标", icon: "icon-mendianguanli1" }],
              textInputList: [{ name: "显示文字", value: "店铺" }],
            },
            {
              name: "购物车",
              isEnable: true,
              link: { url: "/pages/tabbar/shoppCart/index", name: "购物车" }, //跳转链接
              titleIconList: [{ name: "图标", icon: "icon-cart-empty" }],
              textInputList: [{ name: "显示文字", value: "购物车" }],
            },
            // {
            //   name: '客服',
            //   isEnable: false,
            //   link: { url: 'pages/tabbar/shoppCart/index', name: '购物车' }, //跳转链接
            //   titleIconList: [{ name: '图标', icon: 'kefu' }],
            //   textInputList: [{ name: '显示文字', value: '客服' }]
            // },
            {
              name: "自定义",
              isEnable: false,
              link: { url: "", name: "" }, //跳转链接
              titleIconList: [{ name: "图标", icon: "" }],
              textInputList: [{ name: "显示文字", value: "自定义" }],
            },
          ], //图标样式
        };
      } else if (name == "fuwufangshi") {
        //底部导航栏
        obj = {
          name: name,
          isLayout: 0, //样式
          receivingMethod1: "自提", //收货方式1
          receivingMethod2: "配送", //收货方式2
          receivingMethod3: "快递", //收货方式3
          contentStyle: {
            navShape: "ordinary", //导航样式
            iconColor: "#FFFFFF", //图标样式
            textColor: "#FFFFFF", //文字样式
            angleMarkColor: "#FF3C29", //角标样式
            leftBtnColor: "#212121", //左按钮样式
            rightBtnColor: "#FF3C29", //右按钮样式
            bgColor: "#000000", //背景
          }, //内容样式
        };
      } else if (name == "shangpindaohang") {
        //底部导航栏
        obj = {
          name: name,
          //侧边导航
          sideNavStyle: {
            sideNavType: 0, //商品展示方式
            isWaterfallFlow: true, //是否启用瀑布流方式加载跨分类商品
            loadingMode: 0 //瀑布流加载方式
          },
          //商品导航
          goodsStyle: {
            goodsType: 0, //商品展示方式
            isSalesVolume: true, //是否显示销量
            isInventory: true, //是否显示库存
            isSortData: true, //是否显示排序
            isPriceTag: true, //是否显示价格标签
            isPoster: true, //是否显示分类管理上传的分类海报
            titleSize: 14, //标题字体大小
            priceSize: 14, //价格字体大小
            titleColor: "#333333", //标题颜色
            priceTagColor: "#333333", //价格标签颜色
            priceColor: "#fe5a34", //价格颜色
          },
        };
      } else if (name == "biaotilan") {
        //标题栏
        obj = {
          name: name,
          titlePosition: "left", //位置信息
          style: {
            bgColorType: "bgc", //背景类型
            bgColor: "#FFFFFF", //背景
          }, //样式
          link: { url: "", name: "自定义" }, //跳转链接
          title: "快捷工具", //标题
          titleIcon: "", //标题图标
          titleStyle: {
            titleType: "default", //标题样式
            underlineColor: "#FF3C29", //选项条颜色
            fontSize: "14", //标题大小
            textColor: "#212121", //文字颜色
            iconColor: "#FF3C29", //图标颜色
          }, //标题样式
          isAssistantEnable: false, //是否显示副标题
          assistantTitle: "副标题", //副标题
          assistantSetType: "leftRight", //副标题排版样式
          assistantStyle: {
            fontSize: "14", //标题大小
            textColor: "#212121", //文字颜色
          }, //副标题样式
          isSeeMore: true, //查看更多
          seeMoreTitle: "更多", //查看更多
          seeMoreIcon: "icon-jichu03", //查看更多的图标
          seeMoreStyle: {
            fontSize: "14", //标题大小
            textColor: "#969696", //文字颜色
          }, //副标题样式
          marT: 5, //上边距
          marB: 5, //下边距
          marL: 10, //左边距
          marR: 10, //右边距
          borRadius: 10, //圆角
        };
      } else if (name == "huiyuanxinxi") {
        //会员信息
        obj = {
          name: name,
          style: {
            bgImgUrl: "", //背景地址
            bgColorType: "bgc", //背景类型
            bgColor: "#ffd1db", //背景颜色
            nameColor: "#FFFFFF", //昵称颜色
            vipLevelBgColor: "#DF7EA1", //会员等级背景颜色
            vipLevelIconColor: "#ffffff", //会员等级文字颜色
            // shopBgColor: '#3E3F3C', //店铺背景颜色
            // shopIconColor: '#FFFFFF', //店铺图标颜色
            financeNumColor: "#FFFFFF", //财务数字颜色
            financeTitleColor: "#FFFFFF", //财务标题颜色
            financeDividerColor: "#FFFFFF", //财务分割线颜色
          }, //样式
          vipLevelIcon: "icon-shouye-qiehuan", //会员等级图标
          // shopIcon: 'icon-mendianguanli1', //店铺图标
          // vipLevelText: '会员等级', //会员等级内容
          // vipLevelLink: { url: '/vipCard/index', name: '会员等级' }, //会员等级跳转链接
          // homeLink: '/pages/tabbar/home/index', //首页跳转链接
          FinancialBtnList: ["余额", "积分", "优惠券", "收藏"],
        };
      } else if (name == "liebiaodaohang") {
        //列表导航
        obj = {
          name,
          isIcon: true, //是否显示图标
          contentList: [
            {
              title: "全部订单", //标题
              link: { url: "/subPackages/order/myOrder", name: "全部订单" }, //跳转链接
              leftIcon: "icon-quanbudingdan", //左侧查看更多的图标
              style: {
                titleColor: "#333333", //标题颜色
                iconColor: "#333333", //左侧图标颜色
              }, //样式
            },
            {
              title: "全部订单", //标题
              link: { url: "/subPackages/order/myOrder", name: "全部订单" }, //跳转链接
              leftIcon: "icon-quanbudingdan", //左侧查看更多的图标
              style: {
                titleColor: "#333333", //标题颜色
                iconColor: "#333333", //左侧图标颜色
              }, //样式
            },
            {
              title: "全部订单", //标题
              link: { url: "/subPackages/order/myOrder", name: "全部订单" }, //跳转链接
              leftIcon: "icon-quanbudingdan", //左侧查看更多的图标
              style: {
                titleColor: "#333333", //标题颜色
                iconColor: "#333333", //左侧图标颜色
              }, //样式
            },
          ],
          isSeeMore: true, //查看更多
          seeMoreTitle: "更多", //查看更多
          seeMoreIcon: "icon-jichu03", //查看更多的图标
          style: {
            bgColor: "#FFFFFF", //背景
            seeMoreTitleColor: "#999999", //右边标题
            seeMoreIconColor: "#999999", //右边图标
          }, //样式
          marT: 5, //上边距
          marB: 5, //下边距
          marL: 10, //左边距
          marR: 10, //右边距
          borRadius: 10, //圆角
        };
      } else if (name == "tubiaozu") {
        //图标组
        obj = {
          name: name,
          btnType: "icon", //按钮类型
          lineNum: 5, //按钮类型
          contentList: [
            {
              title: "待付款", //标题
              link: {
                url: "/subPackages/order/myOrder?type=1",
                name: "待付款",
              }, //跳转链接
              icon: "icon-daishouhuo1", //图标
              imgUrl: "", //图片
            },
            {
              title: "拼团中", //标题
              link: {
                url: "/subPackages/order/myOrder?type=2",
                name: "拼团中",
              }, //跳转链接
              icon: "icon-pintuan1", //图标
              imgUrl: "", //图片
            },
            {
              title: "待发货", //标题
              link: {
                url: "/subPackages/order/myOrder?type=4",
                name: "待发货",
              }, //跳转链接
              icon: "icon-daishouhuo2", //图标
              imgUrl: "", //图片
            },
            {
              title: "待收货", //标题
              link: {
                url: "/subPackages/order/myOrder?type=5",
                name: "待收货",
              }, //跳转链接
              icon: "icon-daifahuo2", //图标
              imgUrl: "", //图片
            },
            {
              title: "待评价", //标题
              link: {
                url: "/subPackages/order/myOrder?type=7",
                name: "待评价",
              }, //跳转链接
              icon: "icon-pinglun1", //图标
              imgUrl: "", //图片
            },
          ],
          style: {
            bgColor: "#FFFFFF", //背景颜色
            iconColor: "#FF3C29", //图标颜色
            textColor: "#565656", //文字颜色
          }, //样式
          marT: 5, //上边距
          marB: 5, //下边距
          marL: 10, //左边距
          marR: 10, //右边距
          borRadius: 10, //圆角
        };
      } else if (name == "anniuzu") {
        //图标组
        obj = {
          name: name,
          isIcon: true, //是否显示图标呢
          contentList: [
            {
              title: "自定义", //标题
              link: { url: "", name: "" }, //跳转链接
              icon: "", //图标
            },
            {
              title: "自定义", //标题
              link: { url: "", name: "" }, //跳转链接
              icon: "", //图标
            },
          ],
          style: {
            iconColor: "#212121", //图标颜色
            textColor: "#565656", //文字颜色
            splitLineColor: "#EBEBEB", //分割线颜色
            bgColor: "#FFFFFF", //背景颜色
          }, //样式
          marT: 5, //上边距
          marB: 5, //下边距
          marL: 10, //左边距
          marR: 10, //右边距
          borRadius: 10, //圆角
        };
      } else if (name == "kanjia") {
        //  砍价
        obj = {
          name: name,
          style: {
            bgColor: "#ffffff", // 背景颜色
            textColor: "#000000", // 文字颜色
          },
          groupingType: "1", // 拼团类型
          groupingType2: 1, // 拼团类型：获取商品的方式
          //当前列表样式下标
          curListStyleIndex: 1,
          //当前商品样式下标
          curGoodsStyleIndex: 0,
          //选择购物车开关
          selectShoppingCart: false,
          //当前购物车样式下标
          curShoppingCardIndex: 0,
          //购物车按钮文字
          btnText: "马上抢",
          //商品倒角
          goodsBorderAngle: {
            borTL: 0,
            borTR: 0,
            borBR: 0,
            borBL: 0,
          },
          //商品图片宽高
          photoWidthHeight: {
            height: "",
            width: "100%",
          },
          //商品图片宽高(一大两小(混合列表使用))
          photoWidthHeightTwo: {
            height: "",
            width: "100%",
          },
              // 临时数据
          temporaryGoodList: [
            {
              goodsName: "商品名称",
              sellingPoints: "商品描述",
              activityPrice: 99,
              liningPrice: 999,
              goodsUrl: "",
            },
            {
              goodsName: "商品名称",
              sellingPoints: "商品描述",
              activityPrice: 99,
              liningPrice: 999,
              goodsUrl: "",
            },
            {
              goodsName: "商品名称",
              sellingPoints: "商品描述",
              activityPrice: 99,
              liningPrice: 999,
              goodsUrl: "",
            },
          ],
          goodList: [], // 商品列表
          singleGood: {
            goodsName: "商品名称",
            sellingPoints: "商品描述",
            activityPrice: 99,
            liningPrice: 999,
            goodsUrl:
              "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-06-15/0c878c59403b4a7fb461b4cb073047f4.png",
          },
          //更多设置
          moreSet: {
            //是否显示商品名称
            isShowName: true,
            //是否显示商品描述
            isShowDescribe: true,
            //是否显示砍后价
            isShowFightGroupPrice: true,
            //是否显示划线价
            isShowOriginalPrice: true,
            // 是否显示商品倒计时
            isShowCountDown: true,
            // 是否显示已砍人数
            alreadyHaveNum: false,
            //售完商品置底显示
            isSellOutBottom: true,
            //商品倒角
            goodsBorderAngle: "rightAngle",
            //商品比例
            goodsRatio: "1:1",
            //是否图片填充
            isPicFill: true,
            //文本样式(常规/加粗)
            textStyle: "常规",
            //文本对齐(左对齐,居中对齐)
            textAlignment: "left",
            //页面边距
            pageBackGauge: 0,
            //商品边距
            goodsBackGauge: 10,
            //商品角标(不显示0/营销角标1/属性角标2)
            goodsCornerMark: 0,
            //选择角标开关
            selectCornerMark: false,
            //当前角标样式下标
            curCornerMarkIndex: 0,
            //自定义角标url
            cornerMarkUrl: undefined,
          },
        };
      } else if (name == "plusvip") {
        //标题栏
        obj = {
          name: name,
          style: {
            bgColorType: "bgc", //背景类型
            bgColor: "#32353c", //背景
          }, //样式
          link: { url: "/subPackages/plusVip/index", name: "plusVip" }, //跳转链接
          title: "PLUS会员", //标题
          titleUrl:
            "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-03-04/48fbd65470f34579b2d43646e0640065.png", //标题图标
          titleStyle: {
            titleType: "icon", //标题样式
            underlineColor: "#ffdba5", //选项条颜色
            fontSize: "13", //标题大小
            textColor: "#ffdba5", //文字颜色
          }, //标题样式
          assistantTitle: "2026-08-18 06:16:16", //到期时间
          assistantStyle: {
            fontSize: "12", //标题大小
            textColor: "#fec675", //文字颜色
          }, //到期时间样式
          seeMoreTitle: "立即续费", //查看更多
          seeMoreIcon: "icon-jichu03", //查看更多的图标
          seeMoreStyle: {
            fontSize: "12", //标题大小
            textColor: "#fec675", //文字颜色
            iconColor: "#fec675", //图标的颜色
          }, //副标题样式
          marT: 5, //上边距
          marB: 5, //下边距
          marL: 10, //左边距
          marR: 10, //右边距
          borTopRadius: 10, //圆角
          borBottomRadius: 10, //圆角
        };
      } else if (name == "immersive") {
        //沉浸式轮播
        obj = {
          name: name,
          height: "",
          isIcon: true, //是否显示图标呢
          type: 1, // 用户选择的展示样式
          contentList: [
            {
              // title: "#FFFFFF", //标题
              title: "#E61313", //标题
              link: { url: "", name: "" }, //跳转链接
              icon:
                "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-07-29/baf7f52c4de243498b67f0680642723d.png", //图标
            },
            // {
            //   title: '自定义', //标题
            //   link: { url: '', name: '' }, //跳转链接
            //   icon: '' //图标
            // }
          ],
          style: {
            iconColor: "#212121", //图标颜色
            textColor: "#565656", //文字颜色
            splitLineColor: "#EBEBEB", //分割线颜色
            bgColor: "#FFFFFF", //背景颜色
          }, //样式
          marT: 0, //上边距
          marB: 0, //下边距
          marL: 0, //左边距
          marR: 0, //右边距
          borRadius: 10, //圆角
        };
      } else if (name == "navigation") {
        obj = {
          name: name,
          contentStyle: {
            type:1,
            typeName: "图标导航",
            navNumber: 4, //导航数量
            isCustom:false, //是否自定义
            navList:[
              {
                name:'首页', 
                type: 0,
                label:'',
                linkName: '首页',
                linkUrl: '/pages/tabbar/home/index',
                iconName:'icon-home',
                icon: "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-11-04/a2c2ef4494fe46ab8fc4211b8cdfea58.png",
                checkIcon: "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-11-04/ec56f5c2f41e4d71889167df557a9de9.png",
              },
             {
                name:'分类', 
                type: 1,
                label:'',
                linkName: '分类',
                linkUrl: '/pages/tabbar/category/index',
                iconName:'icon-yingyongzhongxin',
                icon: "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-11-04/448b61f0b8424512a682cbe18d685d66.png",
                checkIcon: "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-11-04/6afef13c189d43a196b455cd205dcfb3.png",
              },
             {
                name:'购物车', 
                type: 2,
                label:'',
                linkName: '购物车',
                linkUrl: '/pages/tabbar/shoppCart/index',
                iconName:'icon-gouwuche1-mianxing',
                icon: "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-11-04/925658da76b147b7a7a5a37bc87c3097.png",
                checkIcon: "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-11-04/368bc8489ee74539844c28a279e33af7.png",
              },
             {
                name:'我的', 
                type: 3,
                label:'',
                linkName: '我的',
                linkUrl: '/pages/tabbar/my/index',
                iconName:'icon-wode',
                icon: "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-11-04/3e5f2a7620d741e9b1f0d85207affd98.png",
                checkIcon: "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-11-04/0058e713b0c7475f9d81a7537a2b1e7b.png",
              }
            ],
            textColor: "", //图标样式
            checkTextColor: "", //文字样式
            bgColor: "", //背景
          },
        };
      }
      console.log("---------", JSON.stringify(obj) != "{}");
      if (JSON.stringify(obj) != "{}") return obj;
    },
    // 添加营销组件
    // addMarketing(name) {
    //     if (this.maintainNum(this.marketingIconList, name)) {
    //         return
    //     }
    //     let obj = {}
    //
    //     return obj
    // },
    // 组件鼠标进入
    basicsEnter(index, list) {
      this[list][index].cursor = true;
    },
    // 组件鼠标离开
    basicsleave(index, list) {
      this[list][index].cursor = false;
    },
    // 维护组件数量
    maintainNum(data, name) {
      let result = this[data].some((item) => {
        if (item.name == name) {
          if (item.num < item.maximum) {
            item.num++;
            return false;
          } else {
            return true;
          }
        }
      });
      return result;
    },
    // 添加绝对定位组件
    addFixationTemp(name) {
      let obj = {};
      if (name == "kefu") {
        obj = {
          name: name,
          style: {
            icon: "font-size",
          },
        };
      }
      this.$store.state.template.fixationTempData.push(obj);
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  padding: 10px;
  background: #fdfdfe;
  overflow-y: auto;
  min-width: 300px;
  width: 300px;

  .tag {
    width: 100%;
    height: 40px;
    border-top: 1px solid #eaeaea;
    border-bottom: 1px solid #eaeaea;
    cursor: pointer;

    .item {
      width: 50%;
      height: 100%;
    }

    .item2 {
      color: #1c75f4;
      background: #c0dbf9;
      border-top: 1px solid #1c75f4;
      border-bottom: 1px solid #1c75f4;
    }
  }

  .basics,
  .marketing {
    padding: 15px 10px;

    .icon {
      width: 83px;
      height: 100px;
      padding: 15px 0;
      cursor: pointer;
      border: 1px solid #fdfdfe;
    }

    .cursorBoder {
      border: 1px solid #00afff;
      background: #d4e9fc;
    }
  }
}
</style>
